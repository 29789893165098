<template>
  <v-card>
    <v-card-title class="justify-center">
      <!-- <span class="text-h5"><strong>RECIPIENT INFORMATION FORM</strong></span> -->
    </v-card-title>
    <v-row class="mx-3  pt-3">
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Attention:</p>
      </v-col>
      <v-col cols="12" md="4">
        <!-- <v-text-field v-model="RecipientData.attention" dense hide-details></v-text-field> -->
        <v-text-field v-model="RecipientData.attention" dense hide-details > </v-text-field>
      </v-col>
    </v-row>
    <v-row class="ml-3 mr-5 mb-3">
        <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Tissue ID:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="RecipientData.tissue_id" dense hide-details></v-text-field>
      </v-col>
    </v-row>
    <v-card-title class="justify-center">
      <p class="body-1"><strong>Please complete and return by mail or fax ASAP following the transplant</strong></p>
      <p class="body-2">This form verifies that the tissue referenced was transplanted to the patient indicated below</p>
    </v-card-title>

    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Surgeon and Institution</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" md="2">
        <p class="body-2 mt-4 mb-0">Date of Transplant:</p>
      </v-col>
      <v-col cols="12" md="4" >
         <v-datetime-picker v-model="dateOfTransplant" time-format="HH:mm" date-format="dd-MM-yyyy" :readonly="readOnlyField1 === true"></v-datetime-picker>
      </v-col>
        <v-col cols="12" md="2">
        <p class="body-2 mt-4 mb-0">Surgeon Name:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="RecipientData.surgeon_name" dense hide-details readonly> </v-text-field>
        
      </v-col>
       <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Surgery Location:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="RecipientData.surgery_location" dense hide-details readonly></v-text-field>
        <!-- <v-autocomplete v-model="RecipientData.surgery_location" dense hide-details :items="surgeryLocation" item-text="hospital_name"> </v-autocomplete> -->
      </v-col>
    </v-row>

    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Recipient Information</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-8">
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Family Name:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="RecipientData.family_name" dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Given Name:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="RecipientData.given_name" dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">DOB:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-datetime-picker v-model="DOB" time-format="HH:mm" date-format="dd-MM-yyyy" :text-field-props="textFieldProps" :readonly="readOnlyField1 === true"></v-datetime-picker>
      </v-col>

       <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Age:</p>
      </v-col>
      
      <v-col cols="12" md="4">
        <v-text-field v-model="RecipientData.age" dense hide-details @keypress="onlyAge" @paste.prevent></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">ID(Aadhar or PAN No.):</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="RecipientData.aadhar_pan_no" dense hide-details @keypress="onlyNumber"></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Gender:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete v-model="RecipientData.gender" dense hide-details :items="Gender" :readonly="readOnlyField1 === true"> </v-autocomplete>
      </v-col>
       <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">MR No:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="RecipientData.medical_registration_no" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
       <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Email:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model="RecipientData.email" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      
       <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Hobbies / Interests:</p>
      </v-col>
      <v-col cols="12" md="10">
        <!-- <v-text-field v-model="RecipientData.hobbies_interests" dense hide-details></v-text-field> -->
        <v-textarea v-model="RecipientData.hobbies_interests" hide-details row-height="15" rows="2" class="pt-0 mt-0" :readonly="readOnlyField1 === true"></v-textarea>
      </v-col>
       <v-col cols="12" md="2">
        <p class="body-2 mt-2 mb-0">Address:</p>
      </v-col>
      <v-col cols="12" md="10">
        <v-textarea v-model="RecipientData.address" hide-details row-height="15" rows="2" class="pt-0 mt-0" :readonly="readOnlyField1 === true"></v-textarea>
      </v-col>
    </v-row>
    <v-row class="mx-6 mb-4">
    <v-divider></v-divider>
    </v-row>


    <v-row class="ml-3 mr-5 pt-3 mb-8">
      <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Pre-Operative Diagnosis:</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="RecipientData.pre_operative_diagnosis" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Previous Ocular Surgeries:</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="RecipientData.previous_ocular_surgeries" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Special Concerns:</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="RecipientData.special_concerns"  dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Eye Grafted:</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="RecipientData.eye_grafted" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
       <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Surgery Type:</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="RecipientData.surgery_type" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <p class="body-2 mt-2 mb-0">Surgery Sub-type:</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="RecipientData.surgery_sub_type" dense hide-details :readonly="readOnlyField1 === true"></v-text-field>
      </v-col>
    </v-row>


    <v-card-title class="justify-start">
      <span class="font-weight-semibold">Pre-Operative Checklist</span>
    </v-card-title>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
      <v-col cols="12" sm="6" md="9">
        <p class="mb-0"><strong>1. </strong>Is the tamper-evident seal on the tissue chamber or vial intact ?</p>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-radio-group row class="mt-0" v-model="RecipientData.tamper_evident_seal_or_vial_intact" :readonly="readOnlyField1 === true">
          <v-radio name="Yes" label="Yes" value="Yes"></v-radio>
          <v-radio name="No" label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" md="9">
        <p class="mb-0"><strong>2. </strong>Does the graft number on the tissue lable match the number on all accompanying paperwork ?</p>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-radio-group row class="mt-0" v-model="RecipientData.graft_number_on_the_tissue_label_match" :readonly="readOnlyField1 === true">
          <v-radio name="Yes" label="Yes" value="Yes"></v-radio>
          <v-radio name="No" label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" md="9">
        <p class="mb-0"><strong>3. </strong>Is above recipient information correct? if not please correct ?</p>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-radio-group row class="mt-0" v-model="RecipientData.recipient_information_is_correct" :readonly="readOnlyField1 === true">
          <v-radio name="Yes" label="Yes" value="Yes"></v-radio>
          <v-radio name="No" label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" md="9">
        <p class="mb-0"><strong>4. </strong>Tissue evaluation acceptable ?</p>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-radio-group row class="mt-0" v-model="RecipientData.tissue_evaluation_acceptable" :readonly="readOnlyField1 === true">
          <v-radio name="Yes" label="Yes" value="Yes"></v-radio>
          <v-radio name="No" label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" md="9">
        <p class="mb-0"><strong>5. </strong>
          Indicate performance of steps 1-4 by signing below. Then fax this form to "Eys Bank" @fax number ?
        </p>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-radio-group row class="mt-0" v-model="RecipientData.indicate_performance_of_steps" :readonly="readOnlyField1 === true">
          <v-radio name="Yes" label="Yes" value="Yes"></v-radio>
          <v-radio name="No" label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
     <v-row class="mx-6 mb-4">
    <v-divider></v-divider>
    </v-row>
    <v-row class="ml-3 mr-5 pt-3 mb-3">
       <v-col cols="12" md="4">
        <v-file-input 
          chips 
          v-model="signature"
          accept="image/*" 
          label="Surgeon/Designee Signature" 
          @change="onFileChange" 
        />
        <!-- <img v-img style="width:70%" :src="`https://api.stage.ebsr.in/${RecipientData.surgeon_signature}`" /> -->
        <v-col v-if="!signatureObj && RecipientData.surgeon_signature" cols="8">
          <img  v-img style="width:95%" :src="!signatureObj?`https://api.stage.ebsr.in/${RecipientData.surgeon_signature}`:''" />
       </v-col>
        <v-col v-if="!!signatureObj"  cols="8">
            <img v-img style="width: 95%;" :src="signatureObj?signatureObj:''" />
        </v-col>
       </v-col>
       <v-col cols="12" md="3">
        
      </v-col>
      
       <v-col cols="12" md="4">
         <v-datetime-picker v-model="dateTimeabc" time-format="HH:mm" date-format="dd-MM-yyyy" label="Date and Time" :readonly="readOnlyField1 === true"></v-datetime-picker>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" class="mb-5" @click="same()"> Save </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import donorService from '@/service/DonorForm.service'
import recipientservice from '@/service/RecipientForm.service'
import moment from "moment";
// const localData = JSON.parse(localStorage.getItem("token"));
// const token = localData.token;
import { api } from '@/config/config'
export default {
  data() {
    return {
      formtype:'',
      readOnlyField1:false,
      saveBtn:true,
      surgeonName:[{surgeon_name:''}],
      Attention:[{doctorHospitalName:''}],
      surgeryLocation:[{hospital_name:''}],
      Gender: ['Male', 'Female'],
      RecipientData:{
        tissue_id:'',
        date_of_transplant:'',
        date_of_birth:'',
        date_time:'',
        attention:'',
        surgery_location:'',
        surgeon_name:'',
        family_name:'',
        given_name:'',
        age:'',
        aadhar_pan_no:'',
        gender:'',
        medical_registration_no:'',
        email:'',
        address:'',
        hobbies_interests:'',
        pre_operative_diagnosis:'',
        previous_ocular_surgeries:'',
        special_concerns:'',
        eye_grafted:'',
        surgery_type:'',
        surgery_sub_type:'',
        tamper_evident_seal_or_vial_intact:'',
        graft_number_on_the_tissue_label_match:'',
        recipient_information_is_correct:'',
        tissue_evaluation_acceptable:'',
        indicate_performance_of_steps:'',
        surgeon_signature:'',

      },
      signatureObj:'',
      signature:null,
      image:null,
       listLoading: false,
       timeout: 2000,
       snackbar: false,
       snackbarText: '',
       textFieldProps:{
        dense:'true' ,
        "hide-details":'true'
       }

      
    }
  },
  computed: {

    dateOfTransplant: {
      get() {
        return moment.utc(this.RecipientData.date_of_transplant).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.RecipientData, "date_of_transplant", moment(value).format('YYYY-MM-DD HH:mm'));
      },
    },

    DOB: {
      get() {
        return moment.utc(this.RecipientData.date_of_birth).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.RecipientData, "date_of_birth", moment(value).format('YYYY-MM-DD HH:mm'));
      },
    },

    dateTimeabc: {
      get() {
        return moment.utc(this.RecipientData.date_time).format(
          "DD-MM-YYYY HH:mm"
        );
      },
      set(value) {
        this.$set(this.RecipientData, "date_time", moment(value).format('YYYY-MM-DD HH:mm'));
      },
    },

  },
  watch: {
    options: {
      handler() {
        this.Recipient()
        this.getSurgeonList()
        this.getdonorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.Recipient()
    this.getSurgeonList()
    this.getdonorList()
  },

  methods:{
     same(){
      // if(this.RecipientData.attention == "" || this.RecipientData.attention == null){
      //   return false,
      //   this.snackbarText = "Please Fill the attention.",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.date_of_transplant == "" || this.RecipientData.date_of_transplant == null){
      //   return false,
      //   this.snackbarText = "Please Fill the Date to Transplant",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.surgeon_name == "" || this.RecipientData.surgeon_name == null){
      //   return false,
      //   this.snackbarText = "Please Fill the surgeon Name",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.surgery_location == "" || this.RecipientData.surgery_location == null){
      //   return false,
      //   this.snackbarText = "Please Fill the surgeon location",
      //   this.snackbar = true
      // }
      // // else if(this.RecipientData.family_name == "" || this.RecipientData.family_name == null){
      // //   return false,
      // //   this.snackbarText = "Please Fill the family name",
      // //   this.snackbar = true
      // // }
      // // else if(this.RecipientData.given_name == "" || this.RecipientData.given_name == null){
      // //   return false,
      // //   this.snackbarText = "Please Fill the given name",
      // //   this.snackbar = true
      // // }
      // else if(this.RecipientData.date_of_birth == "" || this.RecipientData.date_of_birth == null){
      //   return false,
      //   this.snackbarText = "Please Fill the date of birth",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.age == "" || this.RecipientData.age == null){
      //   return false,
      //   this.snackbarText = "Please Fill the age",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.aadhar_pan_no == "" || this.RecipientData.aadhar_pan_no == null){
      //   return false,
      //   this.snackbarText = "Please Fill the Aadhar Pan No",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.gender == "" || this.RecipientData.gender == null){
      //   return false,
      //   this.snackbarText = "Please Fill the gender",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.medical_registration_no == "" || this.RecipientData.medical_registration_no == null){
      //   return false,
      //   this.snackbarText = "Please Fill the MR No",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.email == "" || this.RecipientData.email == null){
      //   return false,
      //   this.snackbarText = "Please Fill the email",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.pre_operative_diagnosis == "" || this.RecipientData.pre_operative_diagnosis == null){
      //   return false,
      //   this.snackbarText = "Please Fill the pre operative diagnosis",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.eye_grafted == "" || this.RecipientData.eye_grafted == null){
      //   return false,
      //   this.snackbarText = "Please Fill the eye grafted",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.surgery_type == "" || this.RecipientData.surgery_type == null){
      //   return false,
      //   this.snackbarText = "Please Fill the surgery type",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.surgery_sub_type == "" || this.RecipientData.surgery_sub_type == null){
      //   return false,
      //   this.snackbarText = "Please Fill the surgery sub type",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.tamper_evident_seal_or_vial_intact == "" || this.RecipientData.tamper_evident_seal_or_vial_intact == null){
      //   return false,
      //   this.snackbarText = "Please Fill the tamper evident seal or vial intact",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.graft_number_on_the_tissue_label_match == "" || this.RecipientData.graft_number_on_the_tissue_label_match == null){
      //   return false,
      //   this.snackbarText = "Please Fill the graft number on the tissue label match",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.recipient_information_is_correct == "" || this.RecipientData.recipient_information_is_correct == null){
      //   return false,
      //   this.snackbarText = "Please Fill the recipient information is correct",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.tissue_evaluation_acceptable == "" || this.RecipientData.tissue_evaluation_acceptable == null){
      //   return false,
      //   this.snackbarText = "Please Fill the tissue evaluation acceptable",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.indicate_performance_of_steps == "" || this.RecipientData.indicate_performance_of_steps == null){
      //   return false,
      //   this.snackbarText = "Please Fill the indicate performance of steps",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.surgeon_signature == "" || this.RecipientData.surgeon_signature == null){
      //   return false,
      //   this.snackbarText = "Please Fill the surgeon signature",
      //   this.snackbar = true
      // }
      // else if(this.RecipientData.date_time == "" || this.RecipientData.date_time == null){
      //   return false,
      //   this.snackbarText = "Please Fill the date Time",
      //   this.snackbar = true
      // }
      this.addRecipient()
      // if(this.formtype.view != '0' && this.formtype.view != null){
      //   this.$router.push({name:'TissueDisposition'});
      // }
    },
    
      onlyAge($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2) {
        $event.preventDefault()
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 14) {
        $event.preventDefault()
      }
    },

    onFileChange() {
      this.signatureObj=URL.createObjectURL(this.signature)
      const reader = new FileReader();
      reader.readAsDataURL(this.signature);
      reader.onload = (e) => {
        this.RecipientData.surgeon_signature = e.target.result;
      };
    },

    async Recipient() {
      // const DonorId = this.$route.params.donor_id
      const urlData = this.$route.params.donor_id
      const DonorId = urlData.split('&')[0]
      const service = new recipientservice()
      let response = await service.RecipientDetail_OD(DonorId)
      this.RecipientData = response
       this.SurgeonClick()

       if(response.msg == "link expired"){
        this.$router.push({ name: 'expire-page'});
        this.snackbarText = "This Link is No Longer Valid!"
        this.snackbar = true;
      }
      console.log("hello",response)
      
      // console.log("hello Reci",this.surgeonName)
    },
    async getSurgeonList() {
      const service = new recipientservice()
      let response = await service.SurgeonList()
     var nameValue = (response.data).map(({surgeon_name,hospital_name}) => (
        ({
          doctorHospitalName: `${surgeon_name} ${"("}${hospital_name}${")"}`,
        })
      ))
      this.Attention = nameValue
      this.surgeonName = response.data
      this.surgeryLocation = response.data
    },
    SurgeonClick(){
      let value1 = this.RecipientData.surgeon_name
      let value2 = this.RecipientData.surgery_location
      let newData = `${value1} ${"("}${value2}${")"}`
      // console.log("new data", newData)
       this.RecipientData.attention = newData
    },

   


    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formData = this.permissionData[12]
        this.formtype = this.permissionData[13]
      if(formData.view == "1" && formData.edit == null || formData.view == "1" && formData.edit == "0"){
          this.readOnlyField1 = true
          this.saveBtn = false
        } 
      }else {
      this.totallist = 0
      }
      this.listLoading = false
    },

    async addRecipient() {
      if(this.RecipientData.date_of_transplant == '' || this.RecipientData.date_of_transplant == 'Invalid date' || this.RecipientData.date_of_transplant === null){
        this.RecipientData.date_of_transplant = null
      }
      else{
        this.RecipientData.date_of_transplant = moment.utc(this.RecipientData.date_of_transplant).format('YYYY-MM-DD HH:mm')
      }
      if(this.RecipientData.date_of_birth == '' || this.RecipientData.date_of_birth == 'Invalid date' || this.RecipientData.date_of_birth === null){
        this.RecipientData.date_of_birth = null
      }
      else{
        this.RecipientData.date_of_birth = moment.utc(this.RecipientData.date_of_birth).format('YYYY-MM-DD HH:mm')
      }
      if(this.RecipientData.date_time == '' || this.RecipientData.date_time == 'Invalid date' || this.RecipientData.date_time === null){
        this.RecipientData.date_time = null
      }
      else{
        this.RecipientData.date_time = moment.utc(this.RecipientData.date_time).format('YYYY-MM-DD HH:mm')
      }
     
      const donorid = this.$route.params.donor_id;
      const data = {
        tissue_id:this.RecipientData.tissue_id,
        OD_and_OS: 'OD',
        donor_id:donorid,
        attention:this.RecipientData.attention,
        date_of_transplant:this.RecipientData.date_of_transplant,
        surgery_location:this.RecipientData.surgery_location,
        surgeon_name:this.RecipientData.surgeon_name,
        family_name:this.RecipientData.family_name,
        given_name:this.RecipientData.given_name,
        date_of_birth:this.RecipientData.date_of_birth,
        age:this.RecipientData.age,
        aadhar_pan_no:this.RecipientData.aadhar_pan_no,
        gender:this.RecipientData.gender,
        medical_registration_no:this.RecipientData.medical_registration_no,
        email:this.RecipientData.email ,
        address:this.RecipientData.address,
        hobbies_interests: this.RecipientData.hobbies_interests,
        pre_operative_diagnosis:this.RecipientData.pre_operative_diagnosis,
        previous_ocular_surgeries:this.RecipientData.previous_ocular_surgeries,
        special_concerns:this.RecipientData.special_concerns,
        eye_grafted:this.RecipientData.eye_grafted,
        surgery_type:this.RecipientData.surgery_type,
        surgery_sub_type:this.RecipientData.surgery_sub_type,
        tamper_evident_seal_or_vial_intact:this.RecipientData.tamper_evident_seal_or_vial_intact,
        graft_number_on_the_tissue_label_match:this.RecipientData.graft_number_on_the_tissue_label_match,
        recipient_information_is_correct:this.RecipientData.recipient_information_is_correct,
        tissue_evaluation_acceptable:this.RecipientData.tissue_evaluation_acceptable,
        indicate_performance_of_steps:this.RecipientData.indicate_performance_of_steps,
        surgeon_signature:this.RecipientData.surgeon_signature,
        date_time:this.RecipientData.date_time
      };
      try {
        const response = await api.post(
          `recipientInformation/add`,
          data,
          {
            headers: {
              "Content-type": "application/json",
              // Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status == 200) {
          this.snackbarText = response.data.msg;
          this.Recipient()
        } else {
          this.snackbarText = response.data.msg;
        }
        this.snackbar = true;
      } catch (e) {
        console.log(e);
      }
    },
    
  }
}
</script>
